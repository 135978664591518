import React from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import { useTelegram } from "../../hooks/useTelegram";

import $api from "../../http/";

const Menu: React.FC = () => {
    const { user, onClose } = useTelegram();

    const [menu, setMenu] = React.useState<any>();
    const [linkLk, setLinkLk] = React.useState("");
    const [isLoaded, setIsLoaded] = React.useState(false);

    const onClickGetAccess = () => {
        axios
            .get(
                `https://api2.mastervision.su/public/api/user/telegram?telegram_user_id=${user.id}`
            )
            .then(({ data }) => {
                onClose();
            });
    };

    React.useEffect(() => {
        try {
            $api.get("/header").then(({ data }) => {
                setMenu(data);
                setIsLoaded(true)
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            {isLoaded ? (
                <div className="menu">
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            `menu-item ${isActive ? "active" : ""}`
                        }
                    >
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_2_632)">
                                <path d="M12.636 12C7.867 12 4 16.477 4 22C4 26.855 6.989 30.899 10.952 31.807L14 44H26L23.181 32.725L44 39.647V4L21 12H12.636ZM15.562 42L13.062 32H20.939L23.439 42H15.562ZM12.636 14H21V30H12.636C8.977 30 6 26.411 6 22C6 17.589 8.977 14 12.636 14ZM42 36.874L23 30.557V13.422L42 6.813V36.874Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2_632">
                                    <rect width="48" height="48" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        {menu[0].timetableTitle}
                    </NavLink>

                    <NavLink
                        to="/course"
                        className={({ isActive }) =>
                            `menu-item ${isActive ? "active" : ""}`
                        }
                    >
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_2_620)">
                                <path d="M13 5H5V13H13V5ZM11 11H7V7H11V11Z" />
                                <path d="M28 5H20V13H28V5ZM26 11H22V7H26V11Z" />
                                <path d="M35 13H43V5H35V13ZM37 7H41V11H37V7Z" />
                                <path d="M13 20H5V28H13V20ZM11 26H7V22H11V26Z" />
                                <path d="M28 20H20V28H28V20ZM26 26H22V22H26V26Z" />
                                <path d="M35 28H43V20H35V28ZM37 22H41V26H37V22Z" />
                                <path d="M13 35H5V43H13V35ZM11 41H7V37H11V41Z" />
                                <path d="M28 35H20V43H28V35ZM26 41H22V37H26V41Z" />
                                <path d="M35 43H43V35H35V43ZM37 37H41V41H37V37Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2_620">
                                    <rect width="48" height="48" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        {menu[0].courseTitle}
                    </NavLink>

                    <a href="#" className="menu-item" onClick={onClickGetAccess}>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_2_3)">
                                <path d="M29.089 24.747C32.6 22.909 35 19.238 35 15C35 8.925 30.075 4 24 4C17.925 4 13 8.925 13 15C13 19.238 15.4 22.909 18.911 24.747C10.89 27.09 5 34.809 5 44H43C43 34.809 37.11 27.09 29.089 24.747ZM15 15C15 10.037 19.037 6 24 6C28.963 6 33 10.037 33 15C33 19.963 28.963 24 24 24C19.037 24 15 19.963 15 15ZM24 26C32.735 26 39.953 33.013 40.896 42H7.104C8.047 33.013 15.265 26 24 26Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2_3">
                                    <rect width="48" height="48" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        {menu[0].cabinetTitle}
                    </a>
                    {/* <a href={linkLk} className="menu-item">
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_2_3)">
                                <path d="M29.089 24.747C32.6 22.909 35 19.238 35 15C35 8.925 30.075 4 24 4C17.925 4 13 8.925 13 15C13 19.238 15.4 22.909 18.911 24.747C10.89 27.09 5 34.809 5 44H43C43 34.809 37.11 27.09 29.089 24.747ZM15 15C15 10.037 19.037 6 24 6C28.963 6 33 10.037 33 15C33 19.963 28.963 24 24 24C19.037 24 15 19.963 15 15ZM24 26C32.735 26 39.953 33.013 40.896 42H7.104C8.047 33.013 15.265 26 24 26Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2_3">
                                    <rect width="48" height="48" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        {menu[0].cabinetTitle}
                    </a> */}
                </div>
            ) : null}
        </>
    );
};

export default Menu;
