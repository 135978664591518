import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import { Course } from "../../models/ICourse";

interface CoursePageCoverProps extends Course {}

const CoursePageCover: React.FC<CoursePageCoverProps> = ({
    _id,
    videoUrl,
    image,
    category,
    title,
    description,
    master,
    price,
    isDemo,
    btnTextDemo,
}) => {
    // const getVideoUrl = (url: string): string => {
    //   const splitUrl = url.split("/");
    //   const videoId = splitUrl[splitUrl.length - 1];

    //   return `https://rutube.ru/play/embed/${videoId}`;
    // };

    return (
        <div className="course-page-cover">
            {videoUrl ? (
                <div className="course-page-cover-video">
                    <ReactPlayer
                        url={videoUrl}
                        width={"100%"}
                        height={"auto"}
                        controls
                        playing
                        style={{ borderRadius: "15px", overflow: "hidden" }}
                    />
                </div>
            ) : (
                <div
                    className="course-page-cover-image"
                    style={{
                        backgroundImage: `url('${image}')`,
                    }}
                ></div>
            )}

            <div className="course-page-cover-text">
                <p className="course-page-cover-text__subtitle">{category}</p>

                <h2 className="course-page-cover-text__title">{title}</h2>

                <p
                    className="course-page-cover-text__description"
                    dangerouslySetInnerHTML={{ __html: description }}
                ></p>

                <p className="course-page-cover-text__auth">{master}</p>

                {price !== "" ? (
                    <h3 className="course-page-cover-text__price">{price}</h3>
                ) : null}

                {isDemo ? (
                    <Link
                        to={`/course/${_id}/demo`}
                        className="btn course-page-cover-text__btn"
                    >
                        {btnTextDemo}
                    </Link>
                ) : null}
            </div>
        </div>
    );
};

export default CoursePageCover;